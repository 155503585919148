<template>
<div>
  <!-- LOADING -->
  <div v-if='!dataIsReady' class='page'>
    <div class='flex loading__wrapper'>
      <p class='h2'>Loading...</p>
    </div>
  </div>

  <!-- CONTENT -->
  <div v-else class='report'>
        
    <!-- title -->
    <Page
      key='titlePage'
      id='titlePage'
      :isTranslated='isTranslated'
    >
      <!-- <template #header><HeaderTitlePage /></template> -->
      <template #body><TitlePage /></template>
      <!-- <template #footer><FooterLogos /></template> -->
      <template #footer><div /></template>
    </Page>

    <!-- pages -->  
    <Page v-for='(page, i) in pages' :key='page.component'
      :id='page.component'
      :title='page.title'
      :question='page.question'
      :description='page.description'
      :pageNum='i + 2'
    >
      <template v-if="isTranslated" #header><div /></template>
      <template #body><component :is='page.component' /></template>
      <template v-if="isTranslated" #footer><div /></template>
    </Page>

    <!-- notes -->
    <Page
      :id='notesPage.component'
      :title='notesPage.title'
      :question='notesPage.question'
      :description='notesPage.description'
      :pageNum='pages.length + 2'
    >
      <template v-if="isTranslated" #header><div /></template>
      <template #body><component :is='notesPage.component' /></template>
      <template #footer><FooterLogos /></template>

    </Page>

  </div>
</div>
</template>



<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Page from '../pages/Page.vue';
import TitlePage from '../pages/TitlePage.vue';
import DiseaseDynamics from '../pages/DiseaseDynamics.vue';
import Vaccines from '../pages/Vaccines.vue';
import SupportAdherenceMeasures from '../pages/SupportAdherenceMeasures.vue';
import SupportAdherenceTrust from '../pages/SupportAdherenceTrust.vue';
import RiskPerceptionUnderstand from '../pages/RiskPerceptionUnderstand.vue';
import SecondaryBurdensEssential from '../pages/SecondaryBurdensEssential.vue';
import SecondaryBurdensEconomic from '../pages/SecondaryBurdensEconomic.vue';
import Notes from '../pages/Notes.vue';


import HeaderTitlePage from './HeaderTitlePage.vue';
import FooterLogos from './FooterLogos.vue';

import Prismic from 'prismic-javascript';

export default {
  props: {
    id: String
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['debugView', 'printView', 'reportMetadata', 'countryMetadata', 'reportID']),
    ...mapGetters(['dataIsReady', 'countryData', 'isTranslated']),
    pages() {
      return [
        {
          component: 'DiseaseDynamics',
          title: this.reportMetadata.diseasedynamics_title,
          question: this.reportMetadata.diseasedynamics_question,
          description: this.reportMetadata.diseasedynamics_description
        }, {
          component: 'SupportAdherenceMeasures',
          title: this.reportMetadata.supportadherence_title,
          question: this.reportMetadata.supportadherence_measures_question,
          description: this.reportMetadata.supportadherence_measures_description 
        }, {
          component: 'RiskPerceptionUnderstand',
          title: this.reportMetadata.riskperception_title,
          question: this.reportMetadata.riskperception_understand_question,
          description: this.reportMetadata.riskperception_understand_description
        }, {
          component: 'SupportAdherenceTrust',
          title: this.reportMetadata.riskperception_title,
          question: this.reportMetadata.supportadherence_trust_question,
          description: this.reportMetadata.supportadherence_trust_description 
        }, {
          component: 'Vaccines',
          title: this.reportMetadata.vaccine_title,
          question: this.reportMetadata.vaccine_question,
          description: this.reportMetadata.vaccine_description 
        }, {
          component: 'SecondaryBurdensEssential',
          title: this.reportMetadata.secondaryburdens_title,
          question: this.reportMetadata.secondaryburdens_essential_question,
          description: this.reportMetadata.secondaryburdens_essential_description
        }, {
          component: 'SecondaryBurdensEconomic',
          title: this.reportMetadata.secondaryburdens_title,
          question: this.reportMetadata.secondaryburdens_economic_question,
          description: this.reportMetadata.secondaryburdens_economic_description
        }]
    },
    notesPage() {
      return {
        component: 'Notes',
        title: this.reportMetadata.notes_title,
        question: this.reportMetadata.notes_question
      }
    }
  },
  components: {
    Page,
    TitlePage,
    HeaderTitlePage,
    DiseaseDynamics,
    Vaccines,
    SupportAdherenceMeasures,
    SupportAdherenceTrust,
    RiskPerceptionUnderstand,
    SecondaryBurdensEssential,
    SecondaryBurdensEconomic,
    Notes,
    FooterLogos,
  },
  methods: {
    ...mapActions(['getReportMetadata', 'getCountryMetadata', 'getAllCountryData', 'getLogos', 'resetPrismicData']),
    ...mapMutations(['setReportID']),
  },
  async mounted() {

    // get all the data for this reportID
    this.setReportID(this.id);

    // get Google data
    this.getAllCountryData();

    // getPrismic data
    this.resetPrismicData();
    const api = await Prismic.api("https://resolvetest.cdn.prismic.io/api/v2");
    this.getLogos(api);
    this.getReportMetadata(api);
    this.getCountryMetadata(api);
  },
}
</script>

