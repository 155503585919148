<template>
  <svg :height='height' width='100%' ref='svg'>
    <!-- <rect :height='height' :width='width' fill='white' /> -->


    <!-- chart -->
    <g :transform="'translate(' + margin.left + ',' + margin.top + ')'">
      <!-- background -->
      <!-- <rect :height='chartHeight' :width='chartWidth' /> -->

      <!-- bars & lines & labels -->
      <g v-for='(cat, i) in config.categories' :key='"cat" + i'
        :transform='"translate(0," + (i*barWrapper.height) + ")"'
      >
        <!-- line -->
        <rect :width='chartWidth' height='1' :fill='colors.dark_gray' />

        <!-- axis label -->
        <text v-for='(text, t) in cat' :key="'text' + t"
          x='-10' 
          :y='adjustedY(cat, t)'
          text-anchor='end'
          dominant-baseline='middle'>{{ text }}</text>

        <!-- bar1 -->
        <rect 
          :width='calc(chartWidth * sanitize(config.fieldsGroup1[i])/100)' 
          :fill='colorArr[0]' 
          :y='bar.padding' 
          :height='bar.height' />
        
        <!-- bar2 -->
        <rect 
          :width='calc(chartWidth * sanitize(config.fieldsGroup2[i])/100)' 
          :fill='colorArr[1]' 
          :y='bar.padding+bar.height+bar.internalPadding' 
          :height='bar.height' />

        <!-- data label1 -->
        <text
          :x='calc(chartWidth * sanitize(config.fieldsGroup1[i]) / 100 + 5)' 
          :y='bar.padding+bar.height/2 + 1' 
          dominant-baseline='middle' 
          :fill='colorArr[0]' 
          font-weight='bold' 
          class='datalabel'>{{ config.fieldsGroup1[i] }}</text>

        <text 
          :x='calc(chartWidth * sanitize(config.fieldsGroup2[i]) / 100 + 5)' 
          :y='bar.padding+bar.height+bar.internalPadding+bar.height/2 + 1' 
          dominant-baseline='middle' 
          :fill='colorArr[1]' 
          font-weight='bold' 
          class='datalabel'>{{ config.fieldsGroup2[i] }}</text>
      </g>
    
    </g>


    <!-- yaxis -->
    <g :transform="'translate(0,' + margin.top + ')'">
      <!-- background -->
      <!-- <rect :height='height - margin.top - margin.bottom' :width='margin.left' /> -->

      <!-- line -->
      <!-- <rect :height='chartHeight' width='1' :fill='colors.dark_gray' :x='margin.left' /> -->

    </g>


    <!-- xaxis -->
    <g :transform="'translate(' + margin.left + ',' + (height - margin.bottom) + ')'">
      <!-- background -->
      <!-- <rect :height='margin.bottom' :width='chartWidth' /> -->

      <!-- line -->
      <rect height='1' :width='chartWidth' :fill='colors.dark_gray'/>

      <!-- labels -->
      <g
        v-for='(val, i) in [0, .25, .5, .75, 1]' :key='"i" + i'
        :transform="'translate(' + (chartWidth*val) + ', 0)'"
        >

        <!-- tick marks -->
        <rect 
          height='5' 
          width='1' 
          :fill='colors.dark_gray' />

        <!-- label -->
        <text 
          y=15 
          text-anchor='middle' 
          :fill='colors.darker_gray'>{{ parseInt(100 * val) }}</text>
      </g>
    </g>

    

  </svg>
</template>

<script>
  import cssColors from '../../styles/variables.scss';
  import { removeStar } from '../../utils/utils.js';

  export default {
    props: {
      config: Object
    },
    data() {
      return {
        bar: {
          height: 15,
          padding: 5,
          internalPadding: 2
        },
        width: 2000,
        margin: {
          top: 10, 
          right: 30, 
          bottom: 20, 
          left: 90
        },
        colors: cssColors
      }
    },
    mounted() {
      this.width = this.$refs.svg.clientWidth;
    },
    methods: {
      sanitize(val) {
        return removeStar(val)
      },
      adjustedY(cat, index) {
        // console.log(cat, index, cat[index])
        
        // midline of the two bars
        const center = this.bar.padding + this.bar.height + 1;
        
        // total height to accomodate all labels
        const labelHeight = 10;
        const totHeight = labelHeight * cat.length;

        // where to start placing labels
        // console.log(center, totHeight)
        const start = center - totHeight/2 + labelHeight/2;
        
        return start + labelHeight*index
      },
      calc(val) {
        return val ? val : 0
      }
    },
    computed: {
      colorArr() {
        return [this.colors.chart_primary, this.colors.chart_secondary]
      },
      barWrapper() {
        return {
          height: 2*this.bar.height + 2*this.bar.padding + this.bar.internalPadding 
        }
      },
      numBars() {
        return this.config.fieldsGroup1.length;
      },
      height() {
        return this.margin.top + this.margin.bottom + (this.barWrapper.height * this.numBars)
      },
      chartWidth() {
        return this.width - this.margin.left - this.margin.right;
      },
      chartHeight() {
        return this.height - this.margin.top - this.margin.bottom;
      }
    }
  }
</script>
