<template>
  <svg :height='height' :width='width' ref='svg'>
    <!-- <rect :height='height' :width='width' fill='white' /> -->

    <!-- yaxis -->
    <g v-if='true' :transform="'translate(0,' + margin.top + ')'">
      <!-- background -->
      <!-- <rect :height='height - margin.top - margin.bottom' :width='margin.left' fill='blue' /> -->

      <g
        v-for='(val, i) in [0, .25, .5, .75, 1]' :key='"i" + i'
        :transform="'translate(0,' + (chartHeight*val) + ')'"
        >

        <!-- ticks -->
        <rect height='1' :width='chartWidth' :x='margin.left' :fill='colors.dark_gray' />

        <!-- axis labels -->
        <text :x='margin.left - 12' y='1' text-anchor='end' dominant-baseline='middle' :fill='colors.darker_gray'>{{ parseInt(100 - 100*val) }}</text>
      </g>
    </g>


    <!-- xaxis -->
    <g v-if='true' :transform="'translate(' + margin.left + ',' + (height - margin.bottom) + ')'">
      <!-- background -->
      <!-- <rect :height='margin.bottom' :width='chartWidth' /> -->

      <!-- line -->
      <rect height='1' :width='chartWidth' :fill='colors.dark_gray'/>

      <g
        v-for='(val, i) in config.lineSeries[0].data' :key='"i" + i'
        :transform="'translate(' + xScale(val.x) + ', 0)'"
        >



        <!-- ticks -->
        <rect height='5' width='1' :fill='colors.dark_gray' />
        
        <!-- axis labels -->
        <text y=15 text-anchor='middle'  fill='black'>{{ lookUpDateLabel(val.x) }}</text>
      </g>
    </g>


    <!-- chart -->
    <g v-if='true' :transform="'translate(' + margin.left + ',' + margin.top + ')'">

      <!-- for each line -->
      <g v-for='(line, l) in config.lineSeries' :key="'line' + l">
        
        <!-- draw line -->
        <path
          fill="none"
          stroke-width="4"
          :stroke="colorArr[l]"

          :d="myLine(line.data)" 
        />

        <!-- labels & makers -->
        <g v-for='(point, p) in line.data' :key="'point' + p">
          
          <!-- marker -->
          <circle
            v-if='point.y != ""'
            :fill="colorArr[l]"
            r="4"
            :cx='xScale(point.x)'
            :cy='yScale(sanitize(point.y))'
          />
          
          <!-- labels -->
          <text
            v-if="p != 1 || line.data.length == 2"
            :x='p === 0 ? xScale(point.x) - 10 : xScale(point.x) + 10'
            :y='adjustedYScale(config.lineSeries, l, p)'
            font-weight='bold'
            dominant-baseline='middle'
            :text-anchor="p === 0 ? 'end' : 'start'"
            :fill="colorArr[l]"
            class='datalabel'
            >
            {{ point.y }}
          </text>
        </g>


      </g>
    </g>

    

  </svg>
</template>


<script>
import cssColors from '../../styles/variables.scss';
import { removeStar } from '../../utils/utils.js';

import { line } from "d3-shape";
import { scaleLinear, scaleTime } from 'd3-scale';

import { mapState } from "vuex";




export default {
  props: {
    config: Object,
    width: Number,
    height: Number
  },
  data() {
    return {
      margin: {
        top: 10,
        right: 5, 
        bottom: 20, 
        left: 30
      },
      colors: cssColors
    }
  },
  watch: {
    config: {
      // This will let Vue know to look inside the array
      deep: true,
      handler: function(obj) {
        if (Object.keys(obj).includes('margin')) {
          this.margin = this.config.margin;
        }
      }
    }
  },
  computed: {
    ...mapState(['reportMetadata', 'countryMetadata', 'w2Date', 'w3Date', 'w4Date']),
    cWidth() {
      return this.width === undefined ? 2000 : this.width
    },
    colorArr() {
      return [this.colors.chart_primary, this.colors.chart_secondary]
    },
    chartWidth() {
      return this.cWidth - this.margin.left - this.margin.right;
    },
    chartHeight() {
      return this.height - this.margin.top - this.margin.bottom;
    }
  },
  methods: {
    sanitize(val) {
      return removeStar(val)
    },
    lookUpDateLabel(date) {
      if (date === this.w2Date) {
        return this.reportMetadata.chartlabel_w2_date
      } else if (date === this.w3Date) {
        return this.reportMetadata.chartlabel_w3_date
      } else if (date === this.w4Date) {
        return this.reportMetadata.chartlabel_w4_date
      } else {
        return 'err'
      }
    },
    adjustedYScale(arr, line, point) {
      if (arr.length === 1) {
        // if there's only 1 line, place the label on the line
        return this.yScale(this.sanitize(arr[line].data[point].y)) + 1
      } else {
        // if there are two lines figure out how close they are to one another
        const otherLine = line === 0 ? 1 : 0;
        const thisLinePt = arr[line].data[point].y;
        const otherLinePt = arr[otherLine].data[point].y;

        let thisY = this.yScale(this.sanitize(thisLinePt)) + 1;
        const otherY = this.yScale(this.sanitize(otherLinePt)) + 1;

        const diff = Math.abs(thisY - otherY);

        // if they are far apart, return normal
        if (diff > 14) {
          return thisY
        // if they are too close, spread them apart
        } else {
          const center = (thisY + otherY) / 2;
          const buffer = 7;
          let topPosition = center - buffer;
          let botPosition = center + buffer;

          // if either the min or max is off the chart, fix them accordingly
          if (topPosition < 0) {
            topPosition = 0;
            botPosition = buffer * 2;
          } else if (botPosition > this.chartHeight) {
            botPosition = this.chartHeight;
            topPosition = this.chartHeight - buffer * 2;
          }

          // if the two numbers are equal, the first one gets priority
          if (thisLinePt === otherLinePt) {
            if (line === 0) {
              thisY = thisY + .0001
            }
          }

          // return the right position
          return thisY > otherY ? botPosition : topPosition
        }
      }
    },
    myLine(data) {
      let linePath = line()
        .defined(d => d.y != '') // must have a valid Y to plot
        .x((d) => { return this.xScale(d.x); }) // set the x values for the line generator
        .y((d) => { return this.yScale(this.sanitize(d.y)); }) // set the y values for the line generator
      return linePath(data)
    },
    xScale(x) {
      const startDate = new Date(2020, 5, 1);
      const endDate = new Date(2021, 9, 1);
      let scale = scaleTime()
        .domain([startDate, endDate]) // input
        .range([0, this.chartWidth]); // output
      const out = scale(x);
      return out ? out : 0
    },
    yScale(y) {
      let scale = scaleLinear()
        .domain([0, 100])
        .range([this.chartHeight, 0]) // output
      const out = scale(y);
      return out ? out : 0
    }
  }
}
</script>
