import {store} from '../store/store.js'


export function getReportData(fields) {
	const data = store.state.reportMetadata;
	return fields.map(field => {
		return data[field].map(row => {
			return row.text
		})
	})
}

export function getCountryData(fields) {
	const data = store.getters.countryData;
	return fields.map(field => {
		return data[field]
	})
}

export function mapRichText(arr) {
	return arr.map(x => {
		return x.text
	})
}

export function removeStar(val) {
  //console.log(val, typeof(val), val.replace('*', ''))
  let out = null;
  try {
    out = val.replace('*', '')
  } catch {
    out = val;
  }
  return out
}