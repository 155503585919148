<template>
	<div>

		<!-- report -->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.notes_reportnotes_title }}</p>
			<prismic-rich-text class='text' :field='reportMetadata.notes_reportnotes_text' />
		</div>
		
		<!-- country -->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.notes_countrynotes_title }}</p>
			<div class='text'>
				<p v-if='!isTranslated'>{{ dataSentence }} </p>
				<!-- <p v-if='isTranslated'>{{ dataSentence }} </p> -->
				<prismic-rich-text :field='countryMetadata.notes_countrynotes_text' />
			</div>
		</div>

	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	computed: {
		...mapState(['reportID', 'reportMetadata', 'countryMetadata']),
		...mapGetters(['countryData', 'isTranslated']),
		dataSentence() {
			let out = '';

			try {
				const sentence = this.reportMetadata.notes_countrynotes_data_sentence;
				const sArr = sentence.split('_')

				const country = this.countryMetadata.countryname;
				const adults = this.countryData['latest_survey_data.total_adults'];
				const urban = this.countryData['latest_survey_data.urban_adults'];
				const rural = this.countryData['latest_survey_data.rural_adults'];
				const date = this.countryData['latest_survey_data.date_range'];

				out = sArr[0] + country + sArr[1] + adults + sArr[2] + urban + sArr[3] + rural + sArr[4] + date + sArr[5];		
			} catch {
				out = '';
			}

			return out
		}
	}
}

</script>

