<template>
	<div>

		<!-- content -->
		<div class='section'>
			<div class='grid-12'>

				<!-- situational awareness -->
				<div class='span-12'>
					<p class='h4 section__title'>{{ reportMetadata.diseasedynamics_summary_title }}</p>
					<prismic-rich-text class='text' :field='countryMetadata.diseasedynamics_summary_text' />
				</div>
			</div>
		</div>


		<!-- chart -->
		<div class='section'>
			<div class='h4'>{{ countryMetadata.diseasedynamics_epichart_title1 }}</div>
			<div class='epichart__wrapper-large'>
				<!-- <prismic-image class='epichart__image-large' :field='countryMetadata.diseasedynamics_epichart_image1' /> -->
				<img v-if='countryMetadata.diseasedynamics_epichart_image1.url' :src="countryMetadata.diseasedynamics_epichart_image1.url.replace('auto=compress','')" class='epichart__wrapper-large'>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	computed: {
		...mapState(['reportMetadata', 'countryMetadata']),
		...mapGetters(['countryData']),

	}
}
</script>

