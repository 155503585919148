<template>
	<!-- header -->
	<div class>
		<div class='header'>
			<div class='header__content flex flex-row flex-justify-between'>
				<div class='header__logo'>
					<prismic-image class='header__logo__img filter-white' :field="logos.logo_perc"/>
				</div>
				
				<div class='header__text'>
					<p class='h4 h4-callout is-white'>{{ reportMetadata.titlepage_perc_title }}<span class='is-color-primary pad-left'>{{ reportMetadata.titlepage_perc_report_iteration }}</span></p>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import { mapState } from "vuex";

	export default {
		computed: {
			...mapState(['logos', 'reportMetadata'])
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
