<template>
	<!-- header -->
	<div class='footer footer-logos'>
		<div class='flex flex-justify-between footer__content__wrapper'>
			<prismic-image v-for='(val, i) in logosToDisplay' :key='i' class='footer__logo' :class='{"filter-white": val != "logo_ipsos"}' :field='logos[val]' />
		</div>
	</div>

</template>

<script>
	import { mapState } from "vuex";

	export default {
		props: {
			pageNum: Number
		},
		data() {
			return {
				logosToDisplay: [
					'logo_african_union',
					'logo_africa_cdc',
					'logo_ipsos',
					'logo_lshtm',
					'logo_nasem',
					// 'logo_novetta',
					'logo_rtsl',
					'logo_vital_strategies',
					'logo_wef',
					'logo_who'
				]
			}
		},
		computed: {
			...mapState(['debugView', 'logos']),
		}
	}

</script>

