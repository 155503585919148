<template>
	<div>

		<div class='section'>
			<div class='grid-12'>

				<!-- SUMMARY AND DESCRIPTION -->
				<div class='span-12'>
					<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
					<prismic-rich-text class='text' :field='countryMetadata.supportadherence_measures_summary_text' />
				</div>

			</div>
		</div>


		<!-- BOTTOM	-->
		<div class=''>

			<!-- ROWS -->
			<div v-for='(chartData, i) in chartDataArr' :key='"chartData" + i' class='section-text'>	

				<!-- TITLE -->
				<div class='fullWidthCalloutTitle'>
					<p class='h4 h4-callout'>{{ chartData.title }}</p>
				</div>

				<div class='grid-12'>
					
					<!-- TEXT -->
					<div class='span-3'>
						<prismic-rich-text class='text' :field='chartData.text' />
					</div>

					<!-- BAR CHART -->
					<div class='span-5'>
						<prismic-rich-text class='chart__title is-color-coded' :field='chartData.barchart_title' />
						<GroupedBarChart :config='chartData' />
						<div v-if="chartData.barFootnote" class='footnote chart__title-indent'>
							<p>{{ chartData.barFootnote }}</p>
						</div>
					</div>

					<!-- LINE CHART -->
					<div class='span-4'>
						<prismic-rich-text class='chart__title is-color-coded' :field="chartData.linechart_title" />
						<LineChart :config='chartData' :width='225' :height='114' />
						<div v-if="chartData.lineFootnote" class='footnote chart__title-indent-line'>
							<p>{{ chartData.lineFootnote }}</p>
						</div>
					</div>				
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import GroupedBarChart from '../charts/GroupedBarChart';
import LineChart from '../charts/LineChart.vue';
import { mapState, mapGetters } from "vuex";
import { getReportData, getCountryData } from '../../utils/utils.js';

export default {
	components: {
		GroupedBarChart,
		LineChart
	},
	data() {
		return {
			height3bars: '150px',
			height2bars: '110px'
		}
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata', 'w2Date', 'w3Date', 'w4Date']),
		...mapGetters(['countryData']),
		chartDataArr() {
			return [{

			// personal measures
				barchart_title: this.reportMetadata.supportadherence_measures_personal_barchart_title,
				barFootnote: this.countryMetadata.supportadherence_measures_personal_barchart_footnote,
				linechart_title: this.reportMetadata.supportadherence_measures_personal_linechart_title,
				lineFootnote: this.countryMetadata.supportadherence_measures_personal_linechart_footnote,
				title: this.reportMetadata.supportadherence_measures_personal_title,
				text: this.countryMetadata.supportadherence_measures_personal_text,
				height: this.height3bars,
				categories: getReportData([
					'supportadherence_measures_personal_chartlabel_washinghands',
					'supportadherence_measures_personal_chartlabel_avoidinghandshakes',
					'supportadherence_measures_personal_chartlabel_wearingfacemask'
				]),
				fieldsGroup1: getCountryData([
					'ipsos_phsm_washing_hands_top2_support',
					'ipsos_phsm_avoiding_handshakes_top2_support',
					'ipsos_phsm_wearing_facemasks_top2_support'
				]),
				fieldsGroup2: getCountryData([
					'ipsos_phsm_washing_hands_top2_adherence',
					'ipsos_phsm_avoiding_handshakes_top2_adherence',
					'ipsos_phsm_wearing_facemasks_top2_adherence'
				]),
				lineSeries: [{
					name: 'support',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w2_support
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w3_support
					}, { 
						x: this.w4Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w4_support
					}]
				}, {
					name: 'adherence',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w2_adhere
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w3_adhere
					}, { 
						x: this.w4Date, 
						y: this.countryData.ipsos_phsm_personal_measures_composite_w4_adhere
					}]
				}]
			}, {

			// social measures
				barchart_title: this.reportMetadata.supportadherence_measures_social_barchart_title,
				barFootnote: this.countryMetadata.supportadherence_measures_social_barchart_footnote,
				linechart_title: this.reportMetadata.supportadherence_measures_social_linechart_title,
				lineFootnote: this.countryMetadata.supportadherence_measures_social_linechart_footnote,
				title: this.reportMetadata.supportadherence_measures_social_title,
				text: this.countryMetadata.supportadherence_measures_social_text,
				height: this.height2bars,
				categories: getReportData([
					'supportadherence_measures_social_chartlabel_worship',
					'supportadherence_measures_social_chartlabel_publicgatherings'
				]),
				fieldsGroup1: getCountryData([
					'ipsos_phsm_avoiding_places_worship_top2_support',
					'ipsos_phsm_avoiding_public_gathering_top2_support'
				]),
				fieldsGroup2: getCountryData([
					'ipsos_phsm_avoiding_places_worship_top2_adherence',
					'ipsos_phsm_avoiding_public_gathering_top2_adherence'
				]),
				lineSeries: [{
					name: 'support',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_social_measures_composite_w2_support
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_social_measures_composite_w3_support
					}, {
						x: this.w4Date,
						y: this.countryData.ipsos_phsm_social_measures_composite_w4_support
					}]
				}, {
					name: 'adherence',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_social_measures_composite_w2_adhere
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_social_measures_composite_w3_adhere
					}, {
						x: this.w4Date,
						y: this.countryData.ipsos_phsm_social_measures_composite_w4_adhere
					}]
				}]
			}, {

			// movement
				barchart_title: this.reportMetadata.supportadherence_measures_movement_barchart_title,
				barFootnote: this.countryMetadata.supportadherence_measures_movement_barchart_footnote,
				linechart_title: this.reportMetadata.supportadherence_measures_movement_linechart_title,
				lineFootnote: this.countryMetadata.supportadherence_measures_movement_linechart_footnote,
				title: this.reportMetadata.supportadherence_measures_movement_title,
				text: this.countryMetadata.supportadherence_measures_movement_text,				
				height: this.height2bars,
				categories: getReportData([
					'supportadherence_measures_movement_chartlabel_stayinghome',
					'supportadherence_measures_movement_chartlabel_reducingtrips',
				]),
				fieldsGroup1: getCountryData([
					'ipsos_phsm_staying_home_top2_support',
					'ipsos_phsm_reducing_trips_top2_support'
				]),
				fieldsGroup2: getCountryData([
					'ipsos_phsm_staying_home_top2_adherence',
					'ipsos_phsm_reducing_trips_top2_adherence'
				]),
				lineSeries: [{
					name: 'support',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w2_support
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w3_support
					}, { 
						x: this.w4Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w4_support
					}]
				}, {
					name: 'adherence',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w2_adhere
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w3_adhere
					}, { 
						x: this.w4Date, 
						y: this.countryData.ipsos_phsm_movement_measures_composite_w4_adhere
					}]
				}]	
			}]
		}
	}
}
</script>

