<template>
	<div>

		<!-- top -->
		<div class='section'>
			<div class='grid-12'>

				<!-- What the data says -->
				<div class='span-12'>
					<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
					<prismic-rich-text class='text' :field='countryMetadata.secondaryburdens_essential_summary_text' />
				</div>
			</div>
		</div>

		<!-- meds -->
		<div class='section-text'>
			<div class='fullWidthCalloutTitle'>
				<p class='h4 h4-callout'>{{ reportMetadata.secondaryburdens_essential_meds_title }} </p>
			</div>
			
			<div class='grid-12'>
				
				<!-- TEXT -->
				<div class='span-3'>
					<prismic-rich-text class='text' :field='countryMetadata.secondaryburdens_essential_meds_text' />
				</div>

				<!-- LINE CHART -->
				<div class='span-4'>
					<p class='chart__title'>{{ reportMetadata.secondaryburdens_essential_meds_linechart_title }}</p>
					<LineChart :config='medsLineConfig' :width='225' :height='115' />
					<div v-if="countryMetadata.secondaryburdens_essential_meds_linechart_footnote" class='footnote chart__title-indent-line'>
						<p>{{ countryMetadata.secondaryburdens_essential_meds_linechart_footnote }}</p>
					</div>
				</div>

				<!-- BAR CHART -->
				<div class='span-5'>
					<p class='chart__title'>{{ reportMetadata.secondaryburdens_essential_meds_barchart_title }}</p>
					<BarChart :config='medsBarConfig' />
					<div v-if="countryMetadata.secondaryburdens_essential_meds_barchart_footnote" class='footnote chart__title-indent'>
						<p>{{ countryMetadata.secondaryburdens_essential_meds_barchart_footnote }}</p>
					</div>
				</div>

			</div>
		</div>

		<!-- skipping -->
		<div class=''>
			<div class='fullWidthCalloutTitle'>
				<p class='h4 h4-callout'>{{ reportMetadata.secondaryburdens_essential_skip_title }} </p>
			</div>
			
			<div class='section-text'>
				<div class='grid-12'>
					<!-- TEXT -->
					<div class='span-3'>
						<prismic-rich-text class='text' :field='countryMetadata.secondaryburdens_essential_skip_text' />
					</div>

					<!-- LINE CHART -->
					<div class='span-4'>
						<p class='chart__title'>{{ reportMetadata.secondaryburdens_essential_skip_linechart_title }}</p>
						<LineChart :config='skipLineConfig' :width='225' :height='115' />
						<div v-if="countryMetadata.secondaryburdens_essential_skip_linechart_footnote" class='footnote chart__title-indent-line'>
							<p>{{ countryMetadata.secondaryburdens_essential_skip_linechart_footnote }}</p>
						</div>
					</div>

					<!-- BAR CHART -->
					<div class='span-5'>
						<p class='chart__title'>{{ reportMetadata.secondaryburdens_essential_skip_barchart_title }}</p>
						<BarChart :config='skipBarConfig' />
						<div v-if="countryMetadata.secondaryburdens_essential_skip_barchart_footnote" class='footnote chart__title-indent'>
							<p>{{ countryMetadata.secondaryburdens_essential_skip_barchart_footnote }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class='grid-12 section-text'>
				<div class='span-6'>
					<div class='callout'>
						<div class='callout__table__thead'>
							<p class='h4'>{{ reportMetadata.secondaryburdens_essential_delay_callout1_title }}</p>
							<p class=''>{{ reportMetadata.secondaryburdens_essential_delay_callout1_subtitle }}</p>
							
						</div>

						<!-- why did you skip? -->
						<div class='callout__table__body'>
							<div v-for='(record, r) in whyDidYouSkipArr' :key='"why" + r' class='callout__table__tr'>
								<p>{{ record.label }}</p> 
								<p>{{ record.value }}%</p>
							</div>

							<div v-if="countryMetadata.secondaryburdens_essential_reasons_footnote" class='callout__table__tr footnote'>
								<p>{{ countryMetadata.secondaryburdens_essential_reasons_footnote }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class='span-6'>
					<div class='callout'>
						<div class='callout__table__thead'>
							<p class='h4'>{{ reportMetadata.secondaryburdens_essential_delay_callout2_title }}</p>
							<p class=''>{{ reportMetadata.secondaryburdens_essential_delay_callout2_subtitle }}</p>
						</div>

						<!-- what did you skip? -->
						<div class='callout__table__body'>
							<div v-for='(record, r) in whatDidYouSkipArr' :key='"what"+ r' class='callout__table__tr'>
								<p>{{ record.label }}</p>
								<p>{{ record.value }}%</p>
							</div>
							<!-- footnote -->
							<div v-if="countryMetadata.secondaryburdens_essential_types_footnote" class='callout__table__tr footnote'>
								<p>{{ countryMetadata.secondaryburdens_essential_types_footnote }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class='span-4' v-if='false'>
					<div class='callout'>[[ CALLOUT 3 ]]</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LineChart from '../charts/LineChart.vue';
import BarChart from '../charts/BarChart.vue';
import { getReportData, getCountryData } from '../../utils/utils.js';
import { mapState, mapGetters } from "vuex";

export default {
	components: {
		LineChart,
		BarChart
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata', 'w2Date', 'w3Date', 'w4Date']),
		...mapGetters(['countryData']),
		medsBarConfig() {
			return {
				highlightFirstColor: false,
				width: 290,
				data: getCountryData([
					// 'ipsos_household_disrupted_meds_w3',
					'ipsos_household_disrupted_meds.urban',
					'ipsos_household_disrupted_meds.rural',
					'ipsos_household_disrupted_meds.highincome',
					'ipsos_household_disrupted_meds.lowincome'
				]),
				categories: getReportData([
					// 'chartlabel_overall',
					'chartlabel_urban',
					'chartlabel_rural',
					'chartlabel_highincome',
					'chartlabel_lowincome'
				]),
				gridLines: [true, false, true, false],
				labelSpacing: [-1, 1, -1, 1],
				longGridLines: true

			}
		},
		skipBarConfig() {
			return {
				highlightFirstColor: false,
				width: 290,
				data: getCountryData([
					// 'ipsos_household_skipped_or_delayed_w3',
					'ipsos_household_skipped_or_delayed.urban',
					'ipsos_household_skipped_or_delayed.rural',
					'ipsos_household_skipped_or_delayed.highincome',
					'ipsos_household_skipped_or_delayed.lowincome'
				]),
				categories: getReportData([
					// 'chartlabel_overall',
					'chartlabel_urban',
					'chartlabel_rural',
					'chartlabel_highincome',
					'chartlabel_lowincome'
				]),
				gridLines: [true, false, true, false],
				labelSpacing: [-1, 1, -1, 1],
				longGridLines: true
			}
		},
		medsLineConfig() {
			return {
				margin: {
					top: 10,
					right: 20,
					bottom: 20,
					left: 30
				},
				lineSeries: [{
					name: 'disrupted',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_household_disrupted_meds_w2
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_household_disrupted_meds_w3
					}, { 
						x: this.w4Date, 
						y: this.countryData.ipsos_household_disrupted_meds_w4
					}]
				}]	
			}
		},
		skipLineConfig() {
			return {
				margin: {
					top: 10,
					right: 20,
					bottom: 20,
					left: 30
				},
				lineSeries: [{
					name: 'skipped',
					data: [{ 
						x: this.w2Date, 
						y: this.countryData.ipsos_household_skipped_or_delayed_w2
					}, { 
						x: this.w3Date, 
						y: this.countryData.ipsos_household_skipped_or_delayed_w3
					}, {
						x: this.w4Date, 
						y: this.countryData.ipsos_household_skipped_or_delayed_w4
					}]
				}]
			}
		},
		whyDidYouSkipArr() {
			return [{
				label: this.countryData.ipsos_reasons_skipped_or_delayed_rank_1_label,
				value: this.countryData.ipsos_reasons_skipped_or_delayed_rank_1_percentage
			}, {
				label: this.countryData.ipsos_reasons_skipped_or_delayed_rank_2_label,
				value: this.countryData.ipsos_reasons_skipped_or_delayed_rank_2_percentage
			}, {
				label: this.countryData.ipsos_reasons_skipped_or_delayed_rank_3_label,
				value: this.countryData.ipsos_reasons_skipped_or_delayed_rank_3_percentage
			}, {
				label: this.countryData.ipsos_reasons_skipped_or_delayed_rank_4_label,
				value: this.countryData.ipsos_reasons_skipped_or_delayed_rank_4_percentage
			}, {
				label: this.countryData.ipsos_reasons_skipped_or_delayed_rank_5_label,
				value: this.countryData.ipsos_reasons_skipped_or_delayed_rank_5_percentage
			}]
		},
		whatDidYouSkipArr() {
			return [{
				label: this.countryData.ipsos_visits_skipped_or_delayed_rank_1_label,
				value: this.countryData.ipsos_visits_skipped_or_delayed_rank_1_percentage
			}, {
				label: this.countryData.ipsos_visits_skipped_or_delayed_rank_2_label,
				value: this.countryData.ipsos_visits_skipped_or_delayed_rank_2_percentage
			}, {
				label: this.countryData.ipsos_visits_skipped_or_delayed_rank_3_label,
				value: this.countryData.ipsos_visits_skipped_or_delayed_rank_3_percentage
			}, {
				label: this.countryData.ipsos_visits_skipped_or_delayed_rank_4_label,
				value: this.countryData.ipsos_visits_skipped_or_delayed_rank_4_percentage
			}, {
				label: this.countryData.ipsos_visits_skipped_or_delayed_rank_5_label,
				value: this.countryData.ipsos_visits_skipped_or_delayed_rank_5_percentage
			}]
		}
	}
}
</script>

