<template>
	<div>
		<div class='section section-title hero'>
		
			<!-- title -->
			<div class='page__title__wrapper'>
				<div><p class='h1'>{{ reportMetadata.titlepage_title }} {{ countryMetadata.countryname }}</p></div>
			</div>

			<!-- intro text -->
			<div class='section'>
				<prismic-rich-text class='text' :field='countryMetadata.titlepage_text' />
			</div>
		
			<!-- content -->
			<div class='section'>
				<!-- right column -->
				<!-- intro table callout -->
				<div class='callout callout__float'>
					<!-- title -->
					<div class='callout__table__thead'>
						<div class='h4'>{{ reportMetadata.titlepage_callout_title }}</div>
						<div class='h4'>{{ reportMetadata.titlepage_callout_subtitle }}</div>
					</div>

					<!-- rows -->
					<div class='callout__table__body'>
						<div v-for='(record, r) in calloutDataArr' :key='"r"+r' class='callout__table__tr'>
							<div>
								<p>{{ record.label }}</p>
								<p class='footnote' v-show='record.sublabel'>{{ record.sublabel }}</p>
							</div>
							<div>
								<p class='is-text-align-right'>{{ record.value }}</p>
							</div>
						</div>

						<!-- footnotes -->
						<div class='callout__table__tr footnote'>
							<p><span v-if='reportMetadata.titlepage_callout_reportwide_footnote'>{{ reportMetadata.titlepage_callout_reportwide_footnote }} </span><span v-if='countryMetadata.titlepage_callout_countryspecific_footnote'>{{ countryMetadata.titlepage_callout_countryspecific_footnote }}</span></p>
						</div>
					</div>


				</div>

				<!-- left column -->
				<!-- highlights -->
				<div class=''>
						
					<p class='h2'>{{ reportMetadata.titlepage_highlights_title }}</p>
					<div class='section-text' v-for="(highlight, h) in highlightArr" :key='"highlight" + h'>

						<p class='h4 '>{{ highlight.title }}</p>
						<prismic-rich-text class='text' :field='highlight.text' />

					</div>
				</div>
			
			</div>

			<!-- chart fixed at bottom -->
			<div v-if='false' class='section'>
				<div class='hero__epichart__wrapper flex__item-end'>
					<prismic-image class='hero__epichart' :field="countryMetadata.titlepage_epichart_image" />
				</div>
			</div>

			<!-- policy implications -->
			<div class='section'>
				<p class='h2'>{{ reportMetadata.titlepage_policyimplications_title }}</p>
				<p class='h4'>{{ countryMetadata.titlepage_policyimplications_charttitle }}</p>
				<div class='section-text' >
					<div class='epichart__wrapper-large'>
						<!-- <prismic-image class='epichart__image-large' :field='countryMetadata.titlepage_policyimplications_image' /> -->
						<img v-if='countryMetadata.titlepage_policyimplications_image.url' :src="countryMetadata.titlepage_policyimplications_image.url.replace('auto=compress','')" class='epichart__image-large'>

					</div>
				</div>
			</div>

		</div>

	</div>
</template>


<script>
	// import FrontPageBar from './FrontPageBar.vue';
	import { mapState, mapGetters } from "vuex";

	export default {
		components: {
			// FrontPageBar,
		},
		methods: {
			fmtLang(sentence) {
				const sArr = sentence.split('_')
				const adults = this.countryData['latest_survey_data.total_adults'];
				const out = sArr[0] + adults + sArr[1];
				return out
			},
		},
		computed: {
			...mapState(['debugView', 'reportMetadata', 'countryMetadata']),
			...mapGetters(['countryData']),
			calloutDataArr() {
				return [{
					label: this.reportMetadata.vaccine_callout_1dose_label,
					sublabel: this.reportMetadata.vaccine_callout_1dose_sublabel,
					value: this.countryData.acdc_vx_1dose
				}, {
					label: this.reportMetadata.vaccine_callout_dosesincountry_label,
					sublabel: this.reportMetadata.vaccine_callout_dosesincountry_sublabel,
					value: this.countryData.acdc_vx_supply
				}, {
					label: this.reportMetadata.titlepage_callout_incidencerate_label,
					sublabel: this.reportMetadata.titlepage_callout_incidencerate_sublabel,
					value: this.countryData.acdc_epi_cumulativeincidence
				}, {
					label: this.reportMetadata.titlepage_callout_totalcases_label,
					sublabel: this.reportMetadata.titlepage_callout_totalcases_sublabel,
					value: this.countryData.acdc_epi_totalcases3oct
				}, {
					label: this.reportMetadata.titlepage_callout_totaldeaths_label,
					sublabel: this.reportMetadata.titlepage_callout_totaldeaths_sublabel,
					value: this.countryData.acdc_epi_totaldeaths
				}]
			},
			highlightArr() {
				return [{
					title: this.reportMetadata.diseasedynamics_title,
					text: this.countryMetadata.titlepage_highlight_diseasedynamics_text
				}, {
					title: this.reportMetadata.supportadherence_title,
					text: this.countryMetadata.titlepage_highlight_supportadherence_text
				}, {
					title: this.reportMetadata.riskperception_title,
					text: this.countryMetadata.titlepage_highlight_riskperception_text
				}, {
					title: this.reportMetadata.vaccine_title,
					text: this.countryMetadata.titlepage_highlight_vaccine_text
				}, {
					title: this.reportMetadata.secondaryburdens_title,
					text: this.countryMetadata.titlepage_highlight_secondaryburdens_text
				}]
			},
		},
	}

</script>

