<template>
  <div class='page'>
    
    <div class='login__wrapper flex'>
        <!-- form -->
        <form @submit.prevent='userLogin'>
            
            <!-- welcome -->
            <div class='section'>
              <p class='h2 section-text'>Welcome!</p>
              <p class='h4 is-text-align-center'>Please sign in to view the PERC draft report website</p>
            </div>
            
            <!-- email and pass -->
            <div class='section'>

              <!-- email -->
              <div class='section-text'>
                <div class='login__form h3-padded'>
                    <label class='h3'>Email</label>
                </div>
                <div>
                    <input class='form-email' type='email' v-model='user.email' />
                </div>              
              </div>

              <!-- pass -->
              <div class='section-text'>
                <div class='form-group h3-padded'>
                    <label class='h3'>Password</label>
                </div>
                <div>
                    <input class='form-pass' type='password' v-model='user.password' />
                </div>
              </div>
            </div>

            <!-- button -->
            <button type='submit' class='submit button'>Sign In</button>

        </form>
    </div>
  </div>
</template>


<script>
import { firebase } from '../../firebaseConfig.js'; 


export default {
  props: {
    toRoute: Object
  },
  data() {
    return {
      user: {   
        email: '',
        password: ''
      }
    };
  },
  methods: {
    userLogin() {
      firebase
      .auth()
      .signInWithEmailAndPassword(this.user.email, this.user.password)
      .then(() => {

        // push to new page
        if (this.toRoute != null) {
          this.$router.push(this.toRoute);
        } else {
          this.$router.push('/');
        }
      })
      .catch((error) => {
        alert(error.message);
      });
    }
  }
};
</script>