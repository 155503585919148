import { render, staticRenderFns } from "./HeaderTitlePage.vue?vue&type=template&id=f1a76aa6&scoped=true&"
import script from "./HeaderTitlePage.vue?vue&type=script&lang=js&"
export * from "./HeaderTitlePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a76aa6",
  null
  
)

export default component.exports