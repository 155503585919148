<template>
	<!-- header -->
	<div class='footer'>
		<div class='footer__content__wrapper flex flex-justify-between'>
			<div>{{ countryMetadata.countryname }}</div>
			<div>Page {{ pageNum }}</div>
		</div>
	</div>

</template>

<script>
	import { mapState } from "vuex";
	
	export default {
		props: {
			pageNum: Number
		},
		computed: {
			...mapState(['countryMetadata'])
		}
	}
</script>

