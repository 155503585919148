<template>
  <div class='page'>

    <!-- spacer -->
    <div class='section'/>

    <!-- content -->
    <div class='flex flex-column isFullWidth'>
    
      <!-- title -->
      <div class='section flex flex-column'>
        <p class='h1 section-text'>PERC Report Drafts</p>
        <p class='h4'>click on a country below to see a preview of their PERC report</p>
      </div>
    
      <!-- country list -->
      <div class='country__list__div isFullWidth flex flex-align-start flex-justify-between'>
        
        <div v-for='(lang, l) in Object.keys(countries)' :key='l + "lang"' class='country__list__col'>
          <p class='h2 is-color-secondary'>{{ lang }}</p>
          <div v-for='(country, c) in countries[lang]' :key='c'>
            <router-link class='h3 link link-country' :to="{ name: 'Report', params: { id: country[0] }}" >{{ country[1] }}</router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(['countries']),
  }
}
</script>

