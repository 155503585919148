<template>
	<div>

		<!-- top -->
		<!--  what the data say + callout box -->
		<div class='section'>

			<!-- callout -->
			<div class='callout callout__float'>
				<div class='section-text callout__table__thead'>
					<p class='h4'>{{ reportMetadata.secondaryburdens_economic_callout_reasons_title }}</p>
					<p class=''>{{ reportMetadata.secondaryburdens_economic_callout_reasons_subtitle }}</p>
				</div>

				<div class='callout__table__body'>
					<div v-for='(row, i) in foodRows' :key='"food"+ i' class='callout__table__tr'>
						<p>{{ row.text }}</p>
						<p>{{ row.value }}%</p>
					</div>

					<div v-if="countryMetadata.secondaryburdens_economic_barriers_footnote" class='callout__table__tr footnote'>
						<p>{{ countryMetadata.secondaryburdens_economic_barriers_footnote }}</p>
					</div>
				</div>
			</div>
			
			<!-- summary text -->
			<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
			<prismic-rich-text class='text' :field='countryMetadata.secondaryburdens_economic_summary_text' />
		</div>

		<!-- two horizontal cuts w/ repeating bars -->
		<div class='section'>
			<!-- for each cut -->
			<div v-for='(chartData, i) in chartDataArr' :key='"chartData"+i'>
				<!-- title -->
				<p class='h4 fullWidthCalloutTitle _noMargin'>{{ chartData.title }}</p>
				
				<!-- two charts -->
				<div class='grid-12 section'>

					<!-- spacer -->
					<div class='span-1'></div>

					<!-- bar chart -->
					<div class='span-6'>
						<prismic-rich-text class='chart__title chart__title-indent is-color-coded-primary' :field='chartData.chart1.title' />
						<BarChart class='' :config='chartData.chart1' />
						<p class='footnote chart__title-indent section-text'>{{ chartData.chart1.footnote }}</p>
					</div>


					<!-- line chart -->
					<div class='span-5'>
						<div v-if='!dropObj.supportAdherence_trust_institutions.includes(reportID) | i != 1' >	
							<prismic-rich-text class='chart__title is-color-coded-primary' :field='chartData.chart2.title' />
							<LineChart :config='chartData.chart2' :width='225' :height='130' />
						</div>
						<p class='footnote chart__title-indent-line section-text'>{{ chartData.chart2.footnote }}</p>
					</div>

				</div>
			</div>	
		</div>

	
		<!-- income loss and food insecurity -->
		<div v-if='false' class='section'>

			<!-- chart rows -->
			<div v-for='(chartData, i) in chartDataArr' :key='"chartData"+i'>
				<p class='h4 fullWidthCalloutTitle _noMargin'>{{ chartData.title }}</p>
				<div class='grid-12 section'>
					<div class='span-6'>
						<prismic-rich-text class='chart__title chart__title-indent is-color-coded-primary' :field='reportMetadata.secondaryburdens_economic_incomeloss_chart_title' />
						<BarChart class='' :config='chartData.chart1' />
						<p class='footnote chart__title-indent section-text'>{{ chartData.chart1.footnote }}</p>
					</div>
					<div class='span-6'>
						<prismic-rich-text class='chart__title chart__title-indent is-color-coded-primary' :field='reportMetadata.secondaryburdens_economic_foodinsecurity_chart_title' />
						<BarChart class='' :config='chartData.chart2' />
						<p class='footnote chart__title-indent section-text'>{{ chartData.chart2.footnote }}</p>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import LineChart from '../charts/LineChart.vue';
import BarChart from '../charts/BarChart.vue';
import { getReportData, getCountryData } from '../../utils/utils.js';
import { mapState, mapGetters } from "vuex";

export default {
	components: {
		BarChart,
		LineChart
	},
	data() {
		return {
			labelWidth: 100,
		}
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata', 'w2Date', 'w3Date', 'w4Date', 'dropObj', 'reportID',]),
		...mapGetters(['countryData']),
		foodRows() {
			return [{
				text: this.reportMetadata.secondaryburdens_economic_causes_loss_income,
				value: this.countryData.ipsos_economic_food_insecurity_loss_income
			}, {
				text: this.reportMetadata.secondaryburdens_economic_causes_food_prices,
				value: this.countryData.ipsos_economic_food_insecurity_food_prices
			}, {
				text: this.reportMetadata.secondaryburdens_economic_causes_market_closures,
				value: this.countryData.ipsos_economic_food_insecurity_market_closures
			}, {
				text: this.reportMetadata.secondaryburdens_economic_causes_mobility_restrictions,
				value: this.countryData.ipsos_economic_food_insecurity_mobility_restrictions
			}, {
				text: this.reportMetadata.secondaryburdens_economic_causes_market_shortages,
				value: this.countryData.ipsos_economic_food_insecurity_market_shortages
			}]
		},
		chartDataArr() {
			return [{
				// title: this.reportMetadata.secondaryburdens_economic_householdincome_title,
				title: this.reportMetadata.secondaryburdens_economic_missingmeals_title,
				chart1: {
					title: this.reportMetadata.secondaryburdens_economic_foodinsecurity_chart_title,
					labelWidth: this.labelWidth,
					highlightFirstColor: true,
					footnote: this.countryMetadata.secondaryburdens_economic_missingmeals_footnote,
					data: getCountryData([
						'ipsos_economic_food_insecurity.overall',
						'ipsos_economic_food_insecurity.income.quartile.1',
						'ipsos_economic_food_insecurity.income.quartile.2',
						'ipsos_economic_food_insecurity.income.quartile.3',
						'ipsos_economic_food_insecurity.income.quartile.4'
					]),
					categories: getReportData(['chartlabel_overall']).concat(getCountryData([
						'ipsos_economic_household_income_quartile_1_label',
						'ipsos_economic_household_income_quartile_2_label',
						'ipsos_economic_household_income_quartile_3_label',
						'ipsos_economic_household_income_quartile_4_label'
					])),
					gridLines: [true, false, false, false ]
				},
				chart2: {
					title: this.reportMetadata.secondaryburdens_economic_missingmeals_longitudinal_chart_title,
					margin: {
						top: 10,
						right: 20,
						bottom: 20,
						left: 30
					},
					lineSeries: [{
						name: 'disrupted',
						data: [{ 
						// 	x: this.w2Date, 
						// 	y: this.countryData.ipsos_restricting_meals_w2
						// }, { 
							x: this.w3Date, 
							y: this.countryData.ipsos_restricting_meals_w3
						}, { 
							x: this.w4Date, 
							y: this.countryData.ipsos_restricting_meals_w4
						}]
					}],
					footnote: this.reportMetadata.secondaryburdens_economic_missingmeals_footnote_linechart
				}
			},{
				// title: this.reportMetadata.secondaryburdens_economic_location_title,
				title: this.reportMetadata.secondaryburdens_economic_incomelosssgovtasst_title,
				chart1: {
					title: this.reportMetadata.secondaryburdens_economic_incomeloss_chart_title,
					labelWidth: this.labelWidth,
					highlightFirstColor: true,
					footnote: this.countryMetadata.secondaryburdens_economic_incomeloss_footnote,
					data: getCountryData([
						'ipsos_economic_income_loss.overall',
						'ipsos_economic_income_loss.income.quartile.1',
						'ipsos_economic_income_loss.income.quartile.2',
						'ipsos_economic_income_loss.income.quartile.3',
						'ipsos_economic_income_loss.income.quartile.4'
					]),
					categories: getReportData(['chartlabel_overall']).concat(getCountryData([
						'ipsos_economic_household_income_quartile_1_label',
						'ipsos_economic_household_income_quartile_2_label',
						'ipsos_economic_household_income_quartile_3_label',
						'ipsos_economic_household_income_quartile_4_label'
					])),
					gridLines: [true, false, false, false ]
				},
				chart2: {
					title: this.reportMetadata.secondaryburdens_economic_govtassistance_longitudinal_chart_title,
					margin: {
						top: 10,
						right: 20,
						bottom: 20,
						left: 30
					},
					lineSeries: [{
						name: 'disrupted',
						data: [{ 
							x: this.w2Date, 
							y: this.countryData.ipsos_govt_assistance_w2
						}, { 
							x: this.w3Date, 
							y: this.countryData.ipsos_govt_assistance_w3
						}, { 
							x: this.w4Date, 
							y: this.countryData.ipsos_govt_assistance_w4
						}]
					}],
					footnote: this.countryMetadata.secondaryburdens_economic_incomeloss_footnote_linechart
				}
			}]
		}
	}
}
</script>

