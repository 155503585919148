<template>
	<div>

		<div class='section-text'><p class='section-text chart__title'>{{ reportMetadata.vaccine_caveat}}</p></div>

		<!-- content top -->
		<div class='section'>		
			<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
			<prismic-rich-text class='text' :field='countryMetadata.vaccines_summary_text' />
		</div>


		<!-- content -->
		<div class='section'>

			<!-- TITLE -->
			<div class='section-text'>
				<p class='h4 section__title'>{{ reportMetadata.vaccine_1dose_title }}</p>
				<hr>
			</div>


			<!-- VACCINE SENTENCES -->
			<p class='section-text chart__title'>{{ reportMetadata.vaccine_chart_title_description }}</p>
			<div class='grid-12'>
				<div v-for='(plan, p) in planningArr' :key='"plan"+p' class='span-6'>
					<div class='section-text'>
						<p class='h4 data__sentence'><span class='chart__title__val' :class='{"is-color-primary": plan.bad}'>{{ sanitize(plan.val) }}%</span> {{ plan.text }}</p>
						<div v-if='plan.chart != undefined'>
							<BarChart :config='plan.chart' />
							<div v-if="plan.chart.footnote != undefined" class='footnote chart__title-indent'>
								<p>{{ plan.chart.footnote }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class='grid-12'>
				<div class='span-6'></div>
				<div class='span-6'>
<!-- 					<div class='section-text'>
						<p class='h4 data__sentence'><span class='chart__title__val'>{{ sanitize(countryData['ipsos_unsure_get_vaccine.overall']) }}%</span> {{ reportMetadata.vaccine_unsure_chart_title }}</p>
					</div> -->
				</div>
			</div>
				
		</div>


		<div class='section'>
			<!-- TITLE -->
			<div class='section-text'>
				<p class='h4 section__title'>{{ reportMetadata.riskperception_actions_vaccine_title }}</p>
				<hr>
			</div>

			<div class='grid-12'>
				<!-- right column - thinks about vaccine -->
				<div class='span-6'>
					<!-- callout for more info -->
					<div class='section-text'>
						<div class='callout'>
							<!-- title -->
							<div class='callout__table__thead'>
								<p class='h4'>{{ reportMetadata.vaccine_infowanted_callout_title }}</p>
								<p class=''>{{ reportMetadata.vaccine_infowanted_callout_subtitle }}</p>
							</div>

							<!-- list -->
							<div class='callout__table__body'>
								
								<div v-for='(row, r) in infoCalloutRows' :key='"row" + r' class='callout__table__tr'>
									<div>
										<p>{{ row.text }}</p>
									</div> 
									<div>
										<p>{{ row.value }}%</p>
									</div>
								</div>

								<div v-if="countryMetadata.vaccine_info_footnote" class='callout__table__tr footnote'>
									<p>{{ countryMetadata.vaccine_info_footnote }}</p>
								</div>
							</div>

							<!-- caveat -->
						</div>
					</div>
				</div>

				<div class='span-6'>
			
					<!-- callout for not getting vaccine -->
					<div class='section-text'>
						<div class='callout'>
							<!-- title -->

							<div class='callout__table__thead'>
								<p class='h4'>{{ reportMetadata.riskperception_actions_vaccinecallout_title }}</p>
								<p class=''>{{ reportMetadata.riskperception_actions_vaccinecallout_subtitle }}</p>
							</div>

							<!-- list -->
							<div class='callout__table__body'>
								
								<div v-for='(row, r) in notGetCalloutRows' :key='"row" + r' class='callout__table__tr'>
									<div>
										<p>{{ row.text }}</p>
									</div> 
									<div>
										<p>{{ row.value }}%</p>
									</div>
								</div>

								<div v-if="countryMetadata.riskperception_actions_vaccine_footnote" class='callout__table__tr footnote'>
									<p>{{ countryMetadata.riskperception_actions_vaccine_footnote }}</p>
								</div>
							</div>

							<!-- caveat -->
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
import BarChart from '../charts/BarChart.vue';
import { getReportData, getCountryData, mapRichText } from '../../utils/utils.js';
import { mapState, mapGetters } from "vuex";
import { removeStar } from '../../utils/utils.js';

export default {
	components: {
		BarChart
	},
	methods: {
		sanitize(val) {
			return removeStar(val)
		}
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata']),
		...mapGetters(['countryData']),
		infoCalloutRows() {
			return [{
				text: this.countryData.ipsos_info_wanted_vaccine_rank_1_label,
				value: this.countryData.ipsos_info_wanted_vaccine_rank_1_percentage
			}, {
				text: this.countryData.ipsos_info_wanted_vaccine_rank_2_label,
				value: this.countryData.ipsos_info_wanted_vaccine_rank_2_percentage
			}, {
				text: this.countryData.ipsos_info_wanted_vaccine_rank_3_label,
				value: this.countryData.ipsos_info_wanted_vaccine_rank_3_percentage
			}]
		},
		notGetCalloutRows() {
			return [{
				text: this.countryData.ipsos_reasons_wont_get_vaccine_rank_1_label,
				value: this.countryData.ipsos_reasons_wont_get_vaccine_rank_1_percentage
			}, {
				text: this.countryData.ipsos_reasons_wont_get_vaccine_rank_2_label,
				value: this.countryData.ipsos_reasons_wont_get_vaccine_rank_2_percentage
			}, {
				text: this.countryData.ipsos_reasons_wont_get_vaccine_rank_3_label,
				value: this.countryData.ipsos_reasons_wont_get_vaccine_rank_3_percentage
			}]
		},
		planningArr() {
			return [{
			// 	bad: false,
			// 	text: this.reportMetadata.vaccine_1dose_chart_title,
			// 	val: this.countryData['ipsos_onedose_vaccine.overall'],
			// }, {
			// 	bad: true,
			// }, { 
				bad: false,
				text: this.reportMetadata.vaccine_1dose_chart_title,
				val: this.countryData['ipsos_onedose_vaccine.overall'],
				chart: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_onedose_vaccine.overall',
						'ipsos_onedose_vaccine.region',
						// 'ipsos_onedose_vaccine.urban',
						// 'ipsos_onedose_vaccine.rural',
						'ipsos_onedose_vaccine.highincome',
						'ipsos_onedose_vaccine.lowincome',
						'ipsos_onedose_vaccine.18_25',
						'ipsos_onedose_vaccine.26_35',
						'ipsos_onedose_vaccine.36_45',
						'ipsos_onedose_vaccine.46_55',
						'ipsos_onedose_vaccine.55_plus',
					]),
					categories: getReportData([
						'chartlabel_overall',
						'chartlabel_region',
						// 'chartlabel_urban',
						// 'chartlabel_rural',
						'chartlabel_highincome',
						'chartlabel_lowincome',
						// 'chartlabel_allmemberstates'
						'chartlabel_18_25',
						'chartlabel_26_35',
						'chartlabel_36_45',
						'chartlabel_46_55',
						'chartlabel_56plus',
					]),
					gridLines: [
						true,
						false,
						// true,
						// false,
						true,
						false,
						true,
						false,
						false,
						false,
						false,
					],
					labelSpacing: [
						-1,
						1,
						// -1,
						// 1,
						-1,
						1,
						0,
						0,
						0,
						0,
						0
					],
					longGridLines: true,
					footnote: this.countryMetadata.vaccine_planning_footnote
				}
			}, {
				bad: true,
				val: this.countryData['ipsos_unlikely_to_get_vaccine.overall'],
				text: this.reportMetadata.vaccine_donotplantoget_chart_title,
				chart: {
					highlightFirstColor: true,
					colorScheme: 'bad',
					data: getCountryData([
						'ipsos_unlikely_to_get_vaccine.overall',
						'ipsos_unlikely_to_get_vaccine.region',
						// 'ipsos_unlikely_to_get_vaccine.urban',
						// 'ipsos_unlikely_to_get_vaccine.rural',
						'ipsos_unlikely_to_get_vaccine.highincome',
						'ipsos_unlikely_to_get_vaccine.lowincome',
						'ipsos_unlikely_to_get_vaccine.18_25',
						'ipsos_unlikely_to_get_vaccine.26_35',
						'ipsos_unlikely_to_get_vaccine.36_45',
						'ipsos_unlikely_to_get_vaccine.46_55',
						'ipsos_unlikely_to_get_vaccine.56plus',
					]),
					categories: getReportData([
						'chartlabel_overall',
						'chartlabel_region',
						// 'chartlabel_urban',
						// 'chartlabel_rural',
						'chartlabel_highincome',
						'chartlabel_lowincome',
						// 'chartlabel_allmemberstates'
						'chartlabel_18_25',
						'chartlabel_26_35',
						'chartlabel_36_45',
						'chartlabel_46_55',
						'chartlabel_56plus',
					]),
					gridLines: [
						true,
						false,
						// true,
						// false,
						true,
						false,
						true,
						false,
						false,
						false,
						false,
					],
					labelSpacing: [
						-1,
						1,
						// -1,
						// 1,
						-1,
						1,
						0,
						0,
						0,
						0,
						0
					],
					longGridLines: true,
					footnote: this.countryMetadata.vaccine_unlikely_footnote
				}
			}]
		}
	}
}
</script>

