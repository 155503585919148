<template>
	<div>

		<!-- content top -->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
			<prismic-rich-text class='text' :field='countryMetadata.riskperception_understand_summary_text' />
		</div>

		<!-- content -->
		<div class=grid-12>

			<!-- left column -->
			<div class='span-6'>

				<!-- comparing risk perception -->
				<div class='section'>
					<p class='h4 section__title'>{{ reportMetadata.riskperception_understand_comparingrisk_title }}</p>
					<hr>
					<div class='section-text'>
						<prismic-rich-text class='text' :field='countryMetadata.riskperception_understand_comparingrisk_text' />
					</div>

					<!-- chart loop -->
					<div v-for="(riskData, j) in riskDataArr" :key="j" class='section-text'>
						<p class='h4 data__sentence'><span class='chart__title__val'>{{ sanitize(riskData.titleNum) }}%</span> {{ riskData.titleText }}</p>
						<BarChart :config='riskData.chartConfig' />
						<div v-if="riskData.footnote" class='footnote chart__title-indent'>
							<p>{{ riskData.footnote }}</p>
						</div>
					</div>
				</div>
			</div>
			
			<!-- right column -->
			<div class='span-6'>
				<div class='section'>
					<p class='h4 section__title'>{{ reportMetadata.riskperception_understand_covidandotherconcerns_title }}</p>
					<hr>
					<div class='section-text'>
						<prismic-rich-text class='text' :field='countryMetadata.riskperception_understand_covidandotherconcerns_text' />
					</div>


					<!-- BAR CHART -->
					<!-- chart loop -->
					<div v-for="(concernData, j) in covidConcernData" :key="j" class='section-text'>
						<p class='h4 data__sentence'><span class='chart__title__val'>{{ sanitize(concernData.titleNum) }}%</span> {{ concernData.titleText }}</p>
						<BarChart :config='concernData.chartConfig' />
						<div v-if="concernData.footnote" class='footnote chart__title-indent'>
							<p>{{ concernData.footnote }}</p>
						</div>
					</div>


					<!-- RANK ORDER -->
					<div class='section callout'>
						<div class='callout__table__thead'>
							<p class='h4'>{{ reportMetadata.riskperception_understand_covidandotherconcerns_callout_title }}</p>
							<p class=''>{{ reportMetadata.riskperception_understand_covidandotherconcerns_callout_subtitle }}</p>
							
						</div>

						<div class='callout__table__body'>
							<div v-for='(row, r) in calloutRows' :key='"row" + r' class='callout__table__tr'>
								<div>
									<p>{{ row.text }}</p>
								</div>
								<div>
									<p>{{ row.value }}%</p>
								</div>
							</div>


							<div v-if="countryMetadata.riskperception_understand_covidconcerns_callout_footnote" class='callout__table__tr footnote'>
								<p>{{ countryMetadata.riskperception_understand_covidconcerns_callout_footnote }}</p>
							</div>



						</div>
					</div>

				</div>


			</div>

		</div>

	</div>
</template>

<script>
import BarChart from '../charts/BarChart.vue';
import { getReportData, getCountryData, mapRichText } from '../../utils/utils.js';
import { mapState, mapGetters } from "vuex";
import { removeStar } from '../../utils/utils.js';

export default {
	components: {
		BarChart,
	},
	methods: {
		sanitize(val) {
			return removeStar(val)
		}
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata', 'w2Date', 'w3Date', 'w4Date']),
		...mapGetters(['countryData']),
		calloutRows() {
			return [{
				text: this.countryData.ipsos_covidconcerns_rank_1_label,
				value: this.countryData.ipsos_covidconcerns_rank_1_percentage
			}, {
				text: this.countryData.ipsos_covidconcerns_rank_2_label,
				value: this.countryData.ipsos_covidconcerns_rank_2_percentage
			}, {
				text: this.countryData.ipsos_covidconcerns_rank_3_label,
				value: this.countryData.ipsos_covidconcerns_rank_3_percentage
			}]
		},
		covidConcernData() {
			return [{
				titleNum: this.countryData['ipsos_covid_topconcern.overall'],
				titleText: this.reportMetadata.riskperception_understand_covidtopconcern_chart_title,
				footnote: this.countryMetadata.riskperception_understand_covidtopconcern_footnote,
				chartConfig: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_covid_topconcern.overall',
						'ipsos_covid_topconcern.region',
						// 'ipsos_risk_perceptions.affect_many_people.allcountries'
					]),
					categories: [
						mapRichText(this.countryMetadata.countryname_chartlabel),
						mapRichText(this.reportMetadata.chartlabel_region),
						// mapRichText(this.reportMetadata.chartlabel_allmemberstates)
					],
					gridLines: [true, false]
				}					
			}, {
				titleNum: this.countryData['ipsos_variantconcern.overall'],
				titleText: this.reportMetadata.riskperception_actions_variants_title,
				footnote: this.countryMetadata.riskperception_understand_variants_footnote,
				chartConfig: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_variantconcern.overall',
						// 'ipsos_variantconcern.male',
						// 'ipsos_variantconcern.female',
						'ipsos_variantconcern.urban',
						'ipsos_variantconcern.rural',
						'ipsos_variantconcern.highincome',
						'ipsos_variantconcern.lowincome'
					]),
					categories: getReportData([
						'chartlabel_overall',
						// 'chartlabel_male',
						// 'chartlabel_female',
						'chartlabel_urban',
						'chartlabel_rural',
						'chartlabel_highincome',
						'chartlabel_lowincome'
					]),
					gridLines: [
						true,
						true,
						false,
						true,
						false
					],
					labelSpacing: [
						0,
						-1,
						1,
						-1,
						1
					],
					longGridLines: true,
				},
			}]
		},
		riskDataArr() {
			return [{
				titleNum: this.countryData['ipsos_risk_perceptions.affect_many_people.country'],
				titleText: this.reportMetadata.riskperception_understand_comparingrisk_chart1_title,
				footnote: this.countryMetadata.riskperception_understand_affectpeople_footnote,
				chartConfig: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_risk_perceptions.affect_many_people.country',
						'ipsos_risk_perceptions.affect_many_people.region',
						// 'ipsos_risk_perceptions.affect_many_people.allcountries'
					]),
					categories: [
						mapRichText(this.countryMetadata.countryname_chartlabel),
						mapRichText(this.reportMetadata.chartlabel_region),
						// mapRichText(this.reportMetadata.chartlabel_allmemberstates)
					],
					gridLines: [true, false]
				}
			}, {
				titleNum: this.countryData['ipsos_risk_perceptions.personal_high_risk.country'],
				titleText: this.reportMetadata.riskperception_understand_comparingrisk_chart2_title,
				footnote: this.countryMetadata.riskperception_understand_personalrisk_footnote,
				chartConfig: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_risk_perceptions.personal_high_risk.country',
						'ipsos_risk_perceptions.personal_high_risk.region',
						// 'ipsos_risk_perceptions.personal_high_risk.allcountries'
					]),
					categories: [
						mapRichText(this.countryMetadata.countryname_chartlabel),
						mapRichText(this.reportMetadata.chartlabel_region),
						// mapRichText(this.reportMetadata.chartlabel_allmemberstates)
					],
					gridLines: [true, false]
				}
			}, {
				titleNum: this.countryData['ipsos_risk_perceptions.serious_health_impact.country'],
				titleText: this.reportMetadata.riskperception_understand_comparingrisk_chart3_title,
				footnote: this.countryMetadata.riskperception_understand_healthaffected_footnote,
				chartConfig: {
					highlightFirstColor: true,
					data: getCountryData([
						'ipsos_risk_perceptions.serious_health_impact.country',
						'ipsos_risk_perceptions.serious_health_impact.region',
						// 'ipsos_risk_perceptions.serious_health_impact.allcountries'
					]),
					categories: [
						mapRichText(this.countryMetadata.countryname_chartlabel),
						mapRichText(this.reportMetadata.chartlabel_region),
						// mapRichText(this.reportMetadata.chartlabel_allmemberstates)
					],
					gridLines: [true, false]
				}
			}]
		}
	}
}

</script>

