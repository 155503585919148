<template>
	<div>

		<!-- content top -->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.summary_title }}</p>
			<prismic-rich-text class='text' :field='countryMetadata.supportadherence_trust_summary_text' />
		</div>

		<!-- bar & callout	-->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.supportadherence_trust_govtresponse_title }}</p>
			<hr>
			<div class='grid-12'>

				<!-- bar chart -->
				<div class='span-7'>
					<div class='section-text'>
						<prismic-rich-text class='text' :field='countryMetadata.supportadherence_trust_govtresponse_text' />
					</div>

					<!-- drop for egypt -->
					<div v-if='!dropObj.supportAdherence_trust_institutions.includes(reportID)'>
						<p class='h4 data__sentence'>
							<span class='chart__title__val'>{{ sanitize(countryData['ipsos_government_satisfaction.top2.country']) }}%</span>
							<span> {{ reportMetadata.supportadherence_trust_govtresponse_chart_title }}</span>
						</p>
						<BarChart :config='chartConfig' />
						<div v-if="countryMetadata.supportadherence_trust_govtsatisfaction_footnote" class='footnote chart__title-indent'>
							<p>{{ countryMetadata.supportadherence_trust_govtsatisfaction_footnote }}</p>
						</div>
					</div>
				</div>

				<!-- callout -->
				<div class='span-5'>
					<div class='callout'>
						<div class='callout__table__thead'>
							<p class='h4'>{{ reportMetadata.supportadherence_trust_rankcallout_title }}</p>
							<p class=''>{{ reportMetadata.supportadherence_trust_rankcallout_subtitle }}</p>
							
						</div>

						<div class='callout__table__body'>
							<div v-for='(row, r) in calloutRows' :key='"row" + r' class='callout__table__tr'>
								<div>
									<p>{{ row.text }}</p>
								</div>
								<div>
									<p>{{ row.value }}%</p>
								</div>
							</div>


							<div v-if="countryMetadata.supportadherence_trust_trustedinstitutions_callout_footnote" class='callout__table__tr footnote'>
								<p>{{ countryMetadata.supportadherence_trust_trustedinstitutions_callout_footnote }}</p>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- two lists -->
		<div class='section'>
			<p class='h4 section__title'>{{ reportMetadata.supportadherence_trust_consultedtrusted_title }}</p>
			<hr>
			<div class='section-text'>
					<prismic-rich-text class='text' :field='countryMetadata.riskperception_understand_misinformation_text' />
			</div>
			<div class='grid-12'>


				<!-- left list -->
				<div class='span-6'>
					<!-- callout	-->
					<div class='section-text'>
						<div class='callout'>
							<!-- title -->
							<div class='callout__table__thead'>
								<p class='h4'>{{ reportMetadata.supportadherence_trust_trustedinfocallout_title }}</p>
								<p class=''>{{ reportMetadata.supportadherence_trust_trustedinfocallout_subtitle }}</p>
							</div>

							<!-- list -->
							<div class='callout__table__body'>
								
								<div v-for='(row, r) in trustedCalloutRows' :key='"row" + r' class='callout__table__tr'>
									<div>
										<p>{{ row.text }}</p>
									</div> 
									<div>
										<p>{{ row.value }}%</p>
									</div>
								</div>

								<div v-if="countryMetadata.supportadherence_trust_trustedinfocallout_footnote" class='callout__table__tr footnote'>
									<p>{{ countryMetadata.supportadherence_trust_trustedinfocallout_footnote }}</p>
								</div>
							</div>

							<!-- caveat -->
						</div>
					</div>

				</div>

				<!-- right list -->
				<div class='span-6'>
					<div class='section-text'>
							<p class='h4 section-text data__sentence'><span class='chart__title__val'>{{ sanitize(countryData.ipsos_misinfornation_asymptomatic_can_still_infect) }}%</span> {{ reportMetadata.riskperception_understand_misinformation_chartlabel_asymptomatic[0].text }}</p>

							<p class='h4 section-text data__sentence'><span class='chart__title__val'>{{ sanitize(countryData.ipsos_misinfornation_infected_show_symptoms_5to14) }}%</span> {{ reportMetadata.riskperception_understand_misinformation_chartlabel_delayedsymptoms[0].text }}</p>

							<p class='h4 section-text data__sentence'><span class='chart__title__val is-color-primary'>{{ sanitize(countryData.ipsos_misinformation_herbal_can_cure) }}%</span> {{ reportMetadata.riskperception_understand_misinformation_chartlabel_herbalremedies[0].text }}</p>

							<p class='h4 section-text data__sentence'><span class='chart__title__val is-color-primary'>{{ sanitize(countryData.ipsos_stigma_avoid_healthcare_workers) }}%</span> {{ reportMetadata.riskperception_understand_misinformation_chartlabel_avoidhealthcareworkers[0].text }}</p>

					</div>
				</div>
			</div>
		</div>


			
	</div>
</template>

<script>
import BarChart from '../charts/BarChart.vue';
import { mapRichText, getCountryData } from '../../utils/utils.js';
import { mapState, mapGetters } from "vuex";
import { removeStar } from '../../utils/utils.js';


export default {
	components: {
		BarChart
	},
	methods: {
		sanitize(val) {
			return removeStar(val);
		}
	},
	computed: {
		...mapState(['reportMetadata', 'countryMetadata', 'reportID', 'dropObj']),
		...mapGetters(['countryData']),
		trustedCalloutRows() {
			return [{
				text: this.countryData.ipsos_mosttrustedinfo_rank_1_label,
				value: this.countryData.ipsos_mosttrustedinfo_rank_1_percentage
			}, {
				text: this.countryData.ipsos_mosttrustedinfo_rank_2_label,
				value: this.countryData.ipsos_mosttrustedinfo_rank_2_percentage
			}, {
				text: this.countryData.ipsos_mosttrustedinfo_rank_3_label,
				value: this.countryData.ipsos_mosttrustedinfo_rank_3_percentage
			}]
		},
		calloutRows() {
			return [{
				text: this.countryData.ipsos_most_trusted_rank_1_label,
				value: this.countryData.ipsos_most_trusted_rank_1_percentage
			}, {
				text: this.countryData.ipsos_most_trusted_rank_2_label,
				value: this.countryData.ipsos_most_trusted_rank_2_percentage
			}, {
				text: this.countryData.ipsos_most_trusted_rank_3_label,
				value: this.countryData.ipsos_most_trusted_rank_3_percentage
			}]
		},
		calloutDataArr() {
			return [{
				label: this.reportMetadata.titlepage_callout_totalcases_label,
				sublabel: false,
				value: this.countryData.acdc_epi_totalcases26feb
			}, {
				label: this.reportMetadata.titlepage_callout_incidencerate_label,
				// sublabel: this.reportMetadata.titlepage_callout_incidencerate_description,
				value: this.countryData.acdc_epi_cumulativeincidence
			}, {
				label: this.reportMetadata.titlepage_callout_positivityrate_label,
				sublabel: this.reportMetadata.titlepage_callout_positivityrate_description,
				value: this.countryData.acdc_epi_testpos
			}, {
				label: this.reportMetadata.titlepage_callout_totaldeaths_label,
				sublabel: false,
				value: this.countryData.acdc_epi_totaldeaths
			}, {
				label: this.reportMetadata.titlepage_callout_fatalityrate_label,
				sublabel: this.reportMetadata.titlepage_callout_fatalityrate_description,
				value: this.countryData.acdc_epi_cfr
			}]
		},
		chartConfig() {
			return {
				highlightFirstColor: true,
				data: getCountryData([
					'ipsos_government_satisfaction.top2.country',
					'ipsos_government_satisfaction.top2.region',
					// 'ipsos_government_satisfaction.top2.allcountries' 
				]),
				categories: [
					mapRichText(this.countryMetadata.countryname_chartlabel),
					mapRichText(this.reportMetadata.chartlabel_region),
					// mapRichText(this.reportMetadata.chartlabel_allmemberstates)
				],
				gridLines: [true, false],
				labelWidth: 100
			}
		}
	}
}
</script>

