<template>

  <div class='page flex flex-column flex-justify-start' ref='pageWrapper' :class='{"page__spacer": !printView, "outlined": !printView, "is-overflowing": isOverflowing, "page-overflow": isTranslated }'>
   

    <div ref='header'>
      <slot name='header'>
        <!-- default content -->
        <Header />
      </slot>
    </div>


    <div class='page__content__wrapper' ref='pageContent' :class='{"_is-overflowing": isOverflowing, "page__content__wrapper-overflow": isTranslated}'>
      <div v-if='hasTitle' class='page__title__wrapper'>
        <p class='h3 h3-callout is-color-primary page__title'>{{ title }}</p>
        <p class='h2 h2-callout is-color-secondary page__question'>{{ question }}</p>
        <p class='page__description' v-if='false'>{{ description }}</p>
      </div>

      <div>
        <slot name='body' />
      </div>
    </div>

    <div ref='footer'>
      <slot name='footer'>
        <!-- default content -->
        <Footer :pageNum='pageNum' />
      </slot>
    </div>

  </div>

</template>

<script>
import Header from '../general/Header.vue';
import Footer from '../general/Footer.vue';

import { mapState, mapGetters, mapActions } from "vuex";


export default {
  data() {
    return {
      check: null,
      ro: null,
      isOverflowing: false
    }
  },
  components: {
    Header,
    Footer
  },
  props: {
    id: String,
    title: String,
    question: String,
    description: String,
    pageNum: Number
  },
  methods: {
    ...mapActions(['updateOverflowObj']),
    checkForOverflow() {
      try {
        const page = this.$refs.pageWrapper;
        const header = this.$refs.header;
        const footer = this.$refs.footer;

        const maxHeightContent = page.offsetHeight - header.offsetHeight - footer.offsetHeight;

        const content = this.$refs.pageContent;
        const status = content.offsetHeight > maxHeightContent;  
        // console.log(status)
        this.updateOverflowObj({'id': this.id, 'status': status});
        this.isOverflowing = status;

      } catch (error) {
        console.log(error)
        return false
      }
    }
  },
  mounted () {
    const that = this;
    this.check = window.setInterval(function(){
      that.checkForOverflow();
    }, 1000);


    //this.updateOverflowObj(this.id, false);
    //this.ro = new ResizeObserver(this.onResize);
    //this.ro.observe(this.$refs.pageWrapper);
    //this.ro.observe(this.$refs.pageContent);
  },
  destroyed () {
    //delete this.ro;
    delete this.check;
  },
  computed: {
    ...mapState(['printView']),
    ...mapGetters(['isTranslated']),
    hasTitle() {
      return this.title || this.subtitle || this.description
    }
  }
}

</script>

