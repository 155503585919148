<template>
  <svg :height='height' width='100%' ref='svg'>
    <rect v-if='showBackground' :height='height' :width='width' fill='white' />


    <!-- chart -->
    <g :transform="'translate(' + margin.left + ',' + margin.top + ')'">
      <!-- background -->
      <rect v-if='showBackground' :height='chartHeight' :width='chartWidth' fill='green' />

      <!-- bars & lines & labels -->
      <g v-for='(i, inum) in validIndices' :key='"cat" + inum'
        :transform='"translate(0," + (i*barWrapper.height) + ")"'
      >
        <!-- line -->
        <rect v-if='config.gridLines[i]' :width='chartWidth' height='1' :fill='colors.dark_gray' />

        <rect v-if='config.gridLines[i] & config.longGridLines' :x='-1 * margin.left' :width='chartWidth + margin.left' height='1' :fill='colors.dark_gray' />

        <!-- axis label -->
        <g v-if='Array.isArray(config.categories[i])'>
          <text 
            v-for='(text, t) in config.categories[i]' :key="'text' + t"
            text-anchor='end' 
            x='-10' 
            :y='bar.padding+bar.height/2 + 1 + 10*t + addBuffer(i)' 
            dominant-baseline='middle'>{{ text }}</text>
        </g>
        <g v-else>
          <text
            text-anchor='end' 
            x='-10' 
            :y='bar.padding+bar.height/2 + 1 + addBuffer(i)' 
            dominant-baseline='middle'>{{ config.categories[i] }}</text>
          >
        </g>
        
        <!-- bar -->
        <rect 
          :width='calc(chartWidth * sanitize(config.data[i])/100)' 
          :fill='getBarColor(i)' 
          :y='bar.padding + addBuffer(i)' 
          :height='bar.height' />

        <!-- data label -->
        <text 
          :x='calc(chartWidth * sanitize(config.data[i]) / 100 + 5)' 
          :y='bar.padding+bar.height/2 + 1 + addBuffer(i)' 
          dominant-baseline='middle' 
          :fill='labelColorArray[i]' 
          font-weight='bold' 
          class='datalabel'>{{ config.data[i] }}</text>
      </g>  
    </g>


    <!-- yaxis -->
    <g :transform="'translate(0,' + margin.top + ')'">
      <!-- background -->
      <rect v-if='showBackground' :height='height - margin.top - margin.bottom' :width='margin.left' fill='blue' />

      <!-- line -->
      <!-- <rect :height='chartHeight' width='1' :fill='colors.dark_gray' :x='margin.left' /> -->
    </g>


    <!-- xaxis -->
    <g :transform="'translate(' + margin.left + ',' + (height - margin.bottom) + ')'">
      <!-- background -->
      <rect v-if='showBackground' :height='margin.bottom' :width='chartWidth' fill='red' />

      <!-- line -->
      <rect height='1' :width='chartWidth' :fill='colors.dark_gray'/>

      <!-- labels -->
      <g
        v-for='(val, i) in [0, .25, .5, .75, 1]' :key='"i" + i'
        :transform="'translate(' + (chartWidth*val) + ', 0)'"
        >
        <rect height='5' width='1' :fill='colors.dark_gray' />
        <text y=15 text-anchor='middle' :fill='colors.darker_gray'>{{ parseInt(100 * val) }}</text>
      </g>
    </g>

    
    <!-- right side -->
    <g :transform="'translate(' + (margin.left+chartWidth) + ',0)'">

      <rect v-if='showBackground' :height='height' :width='margin.right' fill='orange' />
    </g>

  </svg>
</template>

<script>
  import cssColors from '../../styles/variables.scss';
  import { removeStar } from '../../utils/utils.js';

  export default {
    props: {
      config: Object
    },
    data() {
      return {
        showBackground: false,
        bar: {
          height: 15,
          padding: 5,
        },
        width: 358,
        margin: {
          top: 10, 
          right: 20, 
          bottom: 20, 
          left: 80
        },
        colors: cssColors,
        colorScheme: null
      }
    },
    watch: {
      config: {
        // This will let Vue know to look inside the array
        deep: true,
        handler: function(obj) {
          if (Object.keys(obj).includes('margin')) {
            this.margin = this.config.margin
          }

          if (Object.keys(obj).includes('width')) {
            this.width = this.config.width
          }

          if (Object.keys(obj).includes('colorScheme')) {
            this.colorScheme = this.config.colorScheme
          }
        }
      }
    },
    mounted() {
      // this.width = this.$refs.svg.clientWidth;
    },
    methods: {
      sanitize(val) {
        return removeStar(val)
      },
      addBuffer(i) {
        const keys = Object.keys(this.config);
        if (keys.includes('labelSpacing')) {
          return this.config.labelSpacing[i] * -3
        } else {
          return 0
        }
      },
      calc(val) {
        return val ? val : 0
      },
      getBarColor(index) {

        if (this.config.highlightFirstColor === false) {
          return cssColors.chart_primary
        } else {
          return this.barColorArray[index]
        }
      }
    },
    computed: {
      barColorArray() {
        let baseArr = [];
        let addColor = cssColors.chart_primary_alt;
        if (this.colorScheme === null) {
          baseArr = [cssColors.rtsl_blue, cssColors.chart_primary_alt];
        } else {
          baseArr = [cssColors.chart_secondary, cssColors.chart_secondary_alt]
          addColor = cssColors.chart_secondary_alt;
        }

        for (let i=2; i<this.numBars; i++) {
          baseArr.push(addColor)
        }

        return baseArr
      },
      labelColorArray() {
        let baseArr = [];
        let addColor = cssColors.chart_primary;
        if (this.colorScheme === null) {
          baseArr = [cssColors.chart_primary, cssColors.chart_primary];
        } else {
          baseArr = [cssColors.chart_secondary, cssColors.chart_secondary,]
          addColor = cssColors.chart_secondary;
        }

        for (let i=2; i<this.numBars; i++) {
          baseArr.push(addColor)
        }

        return baseArr
      },
      barWrapper() {
        return {
          height: this.bar.height + this.bar.padding + this.bar.padding
        }
      },
      validIndices() {
        let indices = [];
        for(let i=0; i<this.config.categories.length; i++) {
          const cat = this.config.categories[i];
          if (cat != '') { indices.push(i) }
        }
        return indices
      },
      numBars() {
        return this.validIndices.length;
      },
      height() {
        return this.margin.top + this.margin.bottom + (this.barWrapper.height * this.numBars)
      },
      chartWidth() {
        return this.width - this.margin.left - this.margin.right;
      },
      chartHeight() {
        return this.height - this.margin.top - this.margin.bottom;
      }
    }
  }
</script>
